<form [formGroup]="loginForm" id="login-form">
  <h2>Discord Graph Menu</h2>
  <mat-icon class="lefticon">face</mat-icon>
  <mat-form-field appearance="fill">
    <input required matInput id="name" formControlName="name" type="text" placeholder="Your discord name" class="input" #user>
    <mat-error>
      <div *ngIf="loginForm?.controls.name?.errors?.required">Required Field</div>
      <div *ngIf="loginForm?.controls.name?.errors?.minlength">Minimum Length is 2</div>
      <div *ngIf="loginForm?.controls.name?.errors?.maxlength">Maximum Length is 20</div>
      <div *ngIf="loginForm?.controls.name?.errors?.pattern">Only alphanumeric and space is allowed in names</div>
    </mat-error>
  </mat-form-field><br/>
  <div class="spacer"></div><br/>
  <h3>Who would you say is the main person who convinced you to join the server? (if godspeed enter quag)</h3>
  <mat-icon class="lefticon">videogame_asset</mat-icon>
  <mat-form-field appearance="fill">
    <input type="text"
           placeholder="Pick one"
           matInput
           formControlName="parent"
           [matAutocomplete]="auto"
           #parent>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let parent of this.filteredParents | async" [value]="parent">
        {{parent}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <br/>
  <div class="spacer"></div><br/>
  <button mat-raised-button color="primary" [disabled]="!this.loginForm.valid" (click)="submit(user.value, parent.value)">Add/Update</button>
  <div>{{statusText}}</div>
</form>
<div class="treeView">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        {{node.name}}
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
