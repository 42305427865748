<span (mouseover)="this.shouldShow = true;"
      (mouseout)="this.shouldShow = false;"
      (mouseleave)="this.shouldShow = false;">
  <img class="selectRoleIcon" [src]="this.icon">
  <div class="fancyTooltipHolder">
    <div *ngIf="this.shouldShow" class="fancyTooltip">
      <h2>
        <img class="selectRoleIcon" [src]="this.icon">{{this.name.charAt(0).toUpperCase() + this.name.slice(1)}}
      </h2>
      {{this.description}}
    </div>
  </div>
</span>
